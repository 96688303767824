import { IArticle, IArticleItem, IFolderItemWithStructure } from "../types";

export enum ActionTypes {
  SetIsLoading = 'set-is-loading',
  SetStructure = 'set-structure',
  SetSearchResult = 'set-search-result',
  SetErrorCode = 'set-error-code',
}

export type StateAction = 
  | { type: ActionTypes.SetIsLoading, payload: { isLoading: boolean }}
  | { type: ActionTypes.SetStructure, payload: { structure: IFolderItemWithStructure, article: IArticle | null }}
  | { type: ActionTypes.SetSearchResult, payload: { searchResults: IArticleItem[], searchText: string }}
  | { type: ActionTypes.SetErrorCode, payload: { errorCode: number }}