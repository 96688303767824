export interface IRawStructureItem {
  name: string
  url: string
  structure?: IRawStructureItem[]
}
export interface IStructureItem extends Omit<IRawStructureItem, 'structure'> {
  encodedURL: string
  structure?: IStructureItem[]
}
export interface IFolderItem extends IStructureItem {
  structure?: (IFolderItem | IArticleItem)[]
}
export interface IArticleItem extends Omit<IStructureItem, 'structure'> {}
export interface IFolderItemWithStructure extends IFolderItem {
  structure: (IFolderItem | IArticleItem)[]
}

export interface IArticle {
  name: string
  text: string
  url: string
  lastModifiedAt: string
}

export enum ViewModes {
  Structure = 'structure',
  Article = 'article',
  SearchResults = 'search-results',
  Error = 'error',
}

export const isStructureItemFolder = (item: IStructureItem): item is IFolderItem => {
  return item.url.endsWith('/') 
}
export const isStructureItemArticle = (item: IStructureItem): item is IArticleItem => {
  return item.url.endsWith('/') === false
}
export const isFolderItemFolderWithStructure = (item: IFolderItem): item is IFolderItemWithStructure => {
  return (item.structure || []).length !== 0
}

export const ROOT_ITEM: IFolderItemWithStructure = {
  name: "Главная",
  url: '/',
  encodedURL: '%2F',
  structure: [],
}