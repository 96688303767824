export const formatDateTime = (timestamp: string): string => {
  return (new Date(timestamp.replace(/-/g, '/'))).toLocaleString()
}

export const getClientNameFromPathname = (pathname: string, defaultValue = ''): string => {
  const matched = /^\/client\/(?<clientName>[^/]+)\/?/i.exec(pathname)
  if (matched && matched.groups) {
    return matched.groups.clientName
  }
  return defaultValue
}

export const getQFromPathname = (pathname: string, defaultValue = ''): string => {
  const matched = /^\/client\/([^/]+)(\/?(?<q>.*))/i.exec(pathname)
  if (matched && matched.groups) {
    return matched.groups.q
  }
  return defaultValue
}

export const getNormalizedURL = (url: string, clientName: string) => {
  if (clientName === '') {
    return `/?q=${encodeURIComponent(url)}`
  }
  return `/client/${clientName}/?q=${encodeURIComponent(url)}`
}