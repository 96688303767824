import React from 'react'
import { Link } from 'react-router-dom'

import './Breadcrumbs.css'

interface IItem {
  label: string
  url: string
}

interface IBreadcrumbsProps {
  items: IItem[]
  className?: string
}

export const Breadcrumbs = ({ 
  items, 
  className = '',
}: IBreadcrumbsProps) => {
  return (
    <div className={`${className} breadcrumbs`}>
      {items.map((item, i) => (
        <BreadcrumbItem 
          key={item.url} 
          url={item.url}
          name={item.label}
          isLast={i + 1 === items.length} 
        />)
      )}
    </div>
  )
}

interface IBreadcrumbItemProps {
  url: string
  name: string
  isLast: boolean
}
const BreadcrumbItem = ({ 
  url,
  name, 
  isLast, 
}: IBreadcrumbItemProps) => {
  if (isLast) {
    return (
      <span className='breadcrumbs__item breadcrumbs__item_is-last_yes'>
        {name}
      </span>
    )
  }
  return (
    <>
      <Link 
        to={url} 
        className='breadcrumbs__item breadcrumbs__link'
      >
        {name}
      </Link>
      {isLast === false && (
        <span className='breadcrumbs__divider'>
          &gt;
        </span>
      )}
    </>
  )
}