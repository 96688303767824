import { 
  IStructureItem, 
  IArticleItem, 
  IFolderItem, 
  IRawStructureItem, 
  isFolderItemFolderWithStructure, 
  isStructureItemArticle, 
  isStructureItemFolder, 
} from "./types"
import { getNormalizedURL } from "./utils"

type GetNormalizedStructureFunc = (item: IRawStructureItem, options: {
  clientName: string
}) => IStructureItem
export const getNormalizedStructure: GetNormalizedStructureFunc = (item, options) => {
  const {
    structure = [],
    ...restOfRawItem
  } = item
  const normalizedItem = {
    ...restOfRawItem,
    encodedURL: getNormalizedURL(item.url, options.clientName),
  }
  if (structure.length === 0) {
    return normalizedItem
  }
  return {
    ...normalizedItem,
    structure: structure.map(si => getNormalizedStructure(si, options)),
  }
}

type GetFinalFolderAndPathFunc = (item: IFolderItem, path?: IFolderItem[]) => {
  finalFolder: IFolderItem
  path: IFolderItem[]
}
export const getFinalFolderAndPath: GetFinalFolderAndPathFunc = (folder, path = []) => {
  path.push({
    name: folder.name,
    url: folder.url,
    encodedURL: folder.encodedURL,
  })
  const nextFolder = (folder.structure || []).find(item => {
    return isStructureItemFolder(item) && isFolderItemFolderWithStructure(item)
  })
  if (nextFolder === undefined) {
    return {
      finalFolder: folder,
      path,
    }
  }
  return getFinalFolderAndPath(nextFolder, path)
}

type GetFoldersAndArticlesFunc = (folder: IFolderItem) => {
  folders: IFolderItem[]
  articles: IArticleItem[]
}
export const getFoldersAndArticles: GetFoldersAndArticlesFunc = folder => {
  return (folder.structure || []).reduce((acc, item) => {
    switch (true) {
      case isStructureItemFolder(item): {
        return {
          ...acc,
          folders: [
            ...acc.folders,
            item,
          ],
        }
      }
      case isStructureItemArticle(item): {
        return {
          ...acc,
          articles: [
            ...acc.articles,
            item,
          ],
        }
      }
      default: {
        return acc
      }
    }
  }, {
    folders: [],
    articles: [],
  } as ReturnType<GetFoldersAndArticlesFunc>)
}