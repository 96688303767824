import React from 'react'
import { Link } from 'react-router-dom'

import { IStructureItem } from '../../types'

import './StructureViewer.css'

interface IProps {
  folders: IStructureItem[]
  articles: IStructureItem[]
  className?: string
}
export const StructureViewer = ({ 
  folders, 
  articles, 
  className = '',
}: IProps) => {
  return (
    <div className={`structure-viewer ${className}`}>
      {folders.map(f => (
        <StructureItem 
          key={f.url} 
          url={f.encodedURL}
          name={f.name}
          itemType='folder'
        />
      ))}
      {articles.map(a => (
        <StructureItem 
          key={a.url} 
          url={a.encodedURL}
          name={a.name}
          itemType='article'
        />
      ))}
    </div>
  )
}

interface IFinderItemProps {
  url: string
  name: string
  itemType: 'article' | 'folder'
}
const StructureItem = ({ 
  url,
  name, 
  itemType,
}: IFinderItemProps) => {
  return (
    <Link
      to={url} 
      className={
        `
        structure-viewer__item 
        structure-viewer__item_type_${itemType}
        structure-viewer__link
        `
      }
    >
      <span 
        className='structure-viewer__name'
        title={name}
      >
        {name}
      </span>
    </Link>
  )
}