import { 
  IArticle, 
  IStructureItem,
  IFolderItemWithStructure,
  ViewModes,
  ROOT_ITEM,
} from "../types"
import { ActionTypes, StateAction } from "./actions"

type State = {
  isLoading: boolean
  viewMode: ViewModes
  errorCode: number
  article: IArticle | null
  structure: IFolderItemWithStructure
  searchText: string
  searchResults: IStructureItem[]
}

export const initialState: State = {
  isLoading: false,
  viewMode: ViewModes.Structure,
  errorCode: 0,
  article: null,
  structure: ROOT_ITEM,
  searchText: '',
  searchResults: [],
}

export const reducer = (state: State, action: StateAction): State => {
  switch (action.type) {

    case ActionTypes.SetIsLoading: {
      const {
        isLoading,
      } = action.payload

      return {
        ...state,
        isLoading,
      }
    }

    case ActionTypes.SetStructure: {
      const {
        article,
        structure,
      } = action.payload

      return {
        ...state,
        article,
        structure,
        viewMode: article !== null ? ViewModes.Article : ViewModes.Structure,
      }
    }

    case ActionTypes.SetSearchResult: {
      const {
        searchText,
        searchResults,
      } = action.payload

      return {
        ...state,
        searchText,
        searchResults,
        viewMode: ViewModes.SearchResults,
      }
    }

    case ActionTypes.SetErrorCode: {
      const {
        errorCode,
      } = action.payload
      
      return {
        ...state,
        errorCode,
        viewMode: ViewModes.Error,
      }
    }

    default:
      throw new Error('not implemented action type')
  }
}