import React from 'react'

import './ErrorBlock.css'

interface IProps {
  errorCode: number
}
export const ErrorBlock = ({ 
  errorCode, 
}: IProps) => {
  return (
    <div className='error-block'>
      <p className='error-block__message'>
        {getErrorMessage(errorCode)}
      </p>
    </div>
  )
}

type GetErrorMessageFunc = (errorCode: number) => string
const getErrorMessage: GetErrorMessageFunc = (errorCode) => {
  switch (errorCode) {
    case 404:
      return "Страница не найдена."
    default:
      return "Произошла ошибка."
  }
}