import React from 'react'
import { Link } from 'react-router-dom'

import { 
  IStructureItem, 
  IFolderItemWithStructure,
  isStructureItemArticle,
  isStructureItemFolder,
  isFolderItemFolderWithStructure, 
} from '../../types'

import './Tree.css'

interface IProps {
  root: IFolderItemWithStructure
  finalFolderURL: string
  selectedArticleURL: string | null
}
export const Tree = ({ 
  root, 
  finalFolderURL,
  selectedArticleURL,
}: IProps) => {
  return (
    <ul className='tree tree_is-root_yes'>
      <TreeItem 
        key={root.url}
        item={root}
        finalFolderURL={finalFolderURL}
        selectedArticleURL={selectedArticleURL}
      />
    </ul>
  )
}

interface ITreeItemProps {
  item: IStructureItem
  finalFolderURL: string
  selectedArticleURL: string | null
}
export const TreeItem = ({ 
  item, 
  finalFolderURL,
  selectedArticleURL,
}: ITreeItemProps) => {
  let itemType: 'folder' | 'article' = 'folder'
  if (isStructureItemArticle(item)) {
    itemType = 'article'
  }

  let isExpanded = false
  if (
    itemType === 'folder'
    && isFolderItemFolderWithStructure(item)
  ) {
    isExpanded = true
  } else if (
    itemType === 'article'
    && item.url === selectedArticleURL
  ) {
    isExpanded = true
  }

  let isHighlighted = false
  if (
    itemType === 'folder'
    && selectedArticleURL === null
    && item.url === finalFolderURL
  ) {
    isHighlighted = true
  } else if (
    itemType === 'article'
    && item.url === selectedArticleURL
  ) {
    isHighlighted = true
  }

  return (
    <li className='tree__item'>
      <Link
        className={
          `
          tree__link
          tree__link_is-bold_${isExpanded ? 'yes' : 'no'}
          tree__link_is-highlighted_${isHighlighted ? 'yes' : 'no'}
          `
        }
        to={item.encodedURL}
      >
        {item.name}
      </Link>
      {isStructureItemFolder(item) && isFolderItemFolderWithStructure(item) && (
        <ul className='tree tree_is-root_no'>
          {item.structure.map(si => (
            <TreeItem 
              key={si.encodedURL}
              item={si} 
              finalFolderURL={finalFolderURL}
              selectedArticleURL={selectedArticleURL}
            />)
          )}
        </ul>
      )}
    </li>
  )
}