import React from 'react'

import './SearchBar.css'

interface IProps {
  searchText: string
  onSearch: (searchText: string) => void
  className?: string
}

export const SearchBar = ({ 
  searchText: initialSearchText,
  onSearch, 
  className = '',
}: IProps) => {
  const [searchText, setSearchText] = React.useState(initialSearchText)
  const [isFocused, setIsFocused] = React.useState(false)

  const handleChange = React.useCallback(({ 
    target: { 
      value, 
    },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(value)
  }, [setSearchText])

  const handleFocus = React.useCallback(() => {
    setIsFocused(true)
  }, [setIsFocused])

  const handleBlur = React.useCallback(() => {
    setIsFocused(false)
  }, [setIsFocused])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (searchText === '') {
      return
    }
    onSearch(searchText)
  }

  return (
    <form 
      onSubmit={handleSubmit}
      className={`${className} search-bar search-bar_is-focused_${isFocused ? 'yes': 'no'}`}
    >
      <input 
        value={searchText}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="search-bar__input" 
        type="text" 
        placeholder="Какой у вас вопрос?" 
      />
      <button 
        className='search-bar__button' 
        type="submit"
      />
    </form>
  )
}