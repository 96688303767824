import React from 'react'

import { IArticle } from '../../types'
import { formatDateTime } from '../../utils'

import './ArticleViewer.css'

interface IProps {
  article: IArticle
}

export const ArticleViewer = ({ 
  article, 
}: IProps) => {
  return (
    <div className='article-viewer'>
      <h1 className='article-viewer__heading'>
        {article.name}
      </h1>
      {article.lastModifiedAt && (
        <time className="article-viewer__timestamp">
          {formatDateTime(article.lastModifiedAt)}
        </time>
      )}
      <article 
        dangerouslySetInnerHTML={{ 
          __html: article.text, 
        }}
        className="article-viewer__body fr-view" 
      />
    </div>
  )
}