import React from 'react'
import { Link } from 'react-router-dom'

import logoURL from './logo.png'

import './LogoBlock.css'

interface IProps {
  clientName: string
  className?: string
}
export const LogoBlock = ({
  clientName,
  className = '',
}: IProps) => {
  return (
    <div className={`${className} logo-block`}>
      <Link
        to={`/client/${clientName}`}
        className="logo-block__link"
      >
        <img 
          className="logo-block__image"
          src={logoURL}
          alt="" 
        />
      </Link>
      <div className='logo-block__divider' />
      <div className='logo-block__text'>
        База знаний
      </div>
    </div>
  )
}