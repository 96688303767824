import React from 'react'
import { useLocation } from 'react-router-dom'

import { reducer, initialState } from './store/reducer'
import { ActionTypes } from './store/actions'
import { 
  IArticle, 
  IFolderItem, 
  IFolderItemWithStructure, 
  IStructureItem, 
  ROOT_ITEM, 
  ViewModes, 
} from './types'
import { getClientNameFromPathname, getNormalizedURL, getQFromPathname } from './utils'
import { getFinalFolderAndPath, getFoldersAndArticles, getNormalizedStructure } from './utils.structure'
import api from './api'

import { Spinner } from './components/Spinner/Spinner'
import { SearchBar } from './components/SearchBar/SearchBar'
import { Tree } from './components/Tree'
import { ArticleViewer } from './components/ArticleViewer/ArticleViewer'
import { ErrorBlock } from './components/ErrorBlock/ErrorBlock'
import { StructureViewer } from './components/StructureViewer'
import { SearchResultViewer } from './components/SearchResultViewer/SearchResultViewer'
import { Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs'
import { LogoBlock } from './components/LogoBlock'

import './App.css'

const fallbackClientName = 'nanotech42'

export default () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const {
    pathname,
    search,
  } = useLocation()

  const clientName = getClientNameFromPathname(pathname, fallbackClientName)
  const q = (new URLSearchParams(search)).get('q') || getQFromPathname(pathname)

  const {
    finalFolder,
    path: pathToFinalFolder,
  } = getFinalFolderAndPath(state.structure)

  React.useEffect(() => {
    setIsLoading()
    api.getContent(q, clientName)
      .then(({ 
        structure: denormalizedStructure, 
        article, 
      }) => {
        const structure = getNormalizedStructure({
          ...ROOT_ITEM,
          structure: denormalizedStructure || [],
        }, {
          clientName,
        }) as IFolderItemWithStructure
        dispatch({ 
          type: ActionTypes.SetStructure, 
          payload: {
            article: article || null,
            structure,
          },
        })
      })
      .catch(e => {
        dispatch({ 
          type: ActionTypes.SetErrorCode, 
          payload: {
            errorCode: 404, 
          },
        })
        console.log(e)
      })
      .finally(() => setIsLoading(false))
  }, [
    clientName,
    q,
  ])

  React.useLayoutEffect(() => {
    const title = getDocumentTitle({
      finalFolder,
      viewMode: state.viewMode,
      article: state.article,
    })
    document.title = `${title} - Nanotech42`
  }, [
    state.viewMode,
    state.article,
    finalFolder,
  ])

  const setIsLoading = React.useCallback((isLoading = true) => {
    dispatch({ 
      type: ActionTypes.SetIsLoading, 
      payload: {
        isLoading,
      }, 
    })
  }, [dispatch])

  if (state.isLoading) {
    return (
      <Spinner />
    )
  }

  const handleSearch = (searchText: string) => {
    setIsLoading()
    api.search(searchText, clientName)
      .then(denormalizedSearchResults => {
        const searchResults = denormalizedSearchResults.map(r => {
          return getNormalizedStructure(r, {
            clientName,
          })
        })
        dispatch({ 
          type: ActionTypes.SetSearchResult, 
          payload: {
            searchResults,
            searchText,
          },
        })
      })
      .catch(e => {
        console.log(e)
        dispatch({ 
          type: ActionTypes.SetErrorCode, 
          payload: {
            errorCode: 404, 
          },
        })
      })
      .finally(() => setIsLoading(false))
  }

  const breadcrumbs = getBreadcrumbs({
    pathToFinalFolder,
    clientName,
    viewMode: state.viewMode,
    article: state.article,
  })

  const selectedArticleURL = getSelectedArticleURL({
    viewMode: state.viewMode,
    article: state.article,
  })

  return (
    <div className="app">
      <div className="app__header">
        <LogoBlock 
          clientName={clientName}
          className="app__logo-block"
        />
        <SearchBar 
          searchText={state.searchText}
          onSearch={handleSearch} 
          className="app__search-bar"
        />
      </div>

      <div className="app__body">
        <div className="app__sidebar">
          <Tree 
            root={state.structure} 
            finalFolderURL={finalFolder.url}
            selectedArticleURL={selectedArticleURL}
          />
        </div>
        <div className="app__content">
          <Breadcrumbs 
            items={breadcrumbs} 
            className="app__breadcrumbs"
          />
          <div className="app__viewer">
            {renderContent({
              finalFolder,
              viewMode: state.viewMode,
              article: state.article,
              searchText: state.searchText,
              searchResults: state.searchResults,
              errorCode: state.errorCode,
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

interface IGetBreadcrumbsProps {
  pathToFinalFolder: IFolderItem[]
  viewMode: ViewModes
  article: IArticle | null
  clientName: string
}
const getBreadcrumbs = ({
  pathToFinalFolder,
  viewMode,
  article,
  clientName,
}: IGetBreadcrumbsProps) => {
  const breadcrumbs = pathToFinalFolder.map(item => {
    return {
      label: item.name,
      url: item.encodedURL,
    }
  })
  if (viewMode === ViewModes.SearchResults) {
    return [
      ...breadcrumbs.slice(0, 1), {
        label: "Результаты поиска",
        url: "#",
      },
    ]
  }
  if (
    viewMode === ViewModes.Article 
    && article
  ) {
    return [
      ...breadcrumbs, {
        label: article.name,
        url: getNormalizedURL(article.url, clientName),
      },
    ]
  }
  return breadcrumbs
}

interface IRenderContentProps {
  viewMode: ViewModes
  finalFolder: IFolderItem
  article: IArticle | null
  searchText: string
  searchResults: IStructureItem[]
  errorCode: number
}
const renderContent = ({
  viewMode,
  finalFolder,
  article,
  searchText,
  searchResults,
  errorCode,
}: IRenderContentProps) => {
  switch (viewMode) {
    case ViewModes.Structure: {
      const {
        folders,
        articles,
      } = getFoldersAndArticles(finalFolder)
      return (
        <StructureViewer 
          folders={folders} 
          articles={articles}
        />
      )
    }

    case ViewModes.Article: {
      if (article === null) {
        return null
      }
      return (
        <ArticleViewer article={article} />
      )
    }
    
    case ViewModes.SearchResults: {
      return (
        <SearchResultViewer 
          searchText={searchText} 
          items={searchResults}
        />
      )
    }

    case ViewModes.Error: {
      return (
        <ErrorBlock errorCode={errorCode} />
      )
    }
  }
}

interface IGetSelectedArticleURLProps {
  viewMode: ViewModes
  article: IArticle | null
}
const getSelectedArticleURL = ({
  viewMode,
  article,
}: IGetSelectedArticleURLProps) => {
  if (viewMode !== ViewModes.Article) {
    return null
  }
  if (article === null) {
    return null
  }
  return article.url
}

interface IGetDocumentTitleProps {
  finalFolder: IFolderItem
  viewMode: ViewModes
  article: IArticle | null
}
const getDocumentTitle = ({
  finalFolder,
  viewMode,
  article
}: IGetDocumentTitleProps): string => {
  switch (viewMode) {
    case ViewModes.Article: {
      if (article === null) {
        return "Статья не найдена"
      }
      return article.name
    }
    case ViewModes.Structure:
      return finalFolder.name
    case ViewModes.SearchResults: 
      return "Результаты поиска"
    case ViewModes.Error: {
      return "Произошла ошибка"
    }
    default: 
      return "База знаний"
  }
}