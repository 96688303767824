import React from 'react'

import { IStructureItem } from '../../types'
import { StructureViewer } from '../StructureViewer'

import './SearchResultViewer.css'

interface IProps {
  searchText: string
  items: IStructureItem[]
}

export const SearchResultViewer = ({ 
  searchText, 
  items, 
}: IProps) => {
  if (items.length === 0) {
    return (
      <div className='search-result-viewer'>
        <p className="search-result-viewer__message">
          По запросу <strong>{`"${searchText}"`}</strong> ничего не найдено.
        </p>
      </div>
    )
  }
  return (
    <div className='search-result-viewer'>
      <p className="search-result-viewer__message">
        Вот что нам удалось найти:
      </p>
      <StructureViewer 
        articles={items}
        folders={[]}
        className='search-result-viewer__structure-viewer'
      />
    </div>
  )
}