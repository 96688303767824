import { IArticle, IRawStructureItem, IStructureItem } from "./types"

interface IStructureResponse {
  structure?: IRawStructureItem[]
  article?: IArticle
}

const DOMAIN = (() => {
  if (process.env.NODE_ENV === 'development') {
    return 'kb.nt42.dev.livetex.ru'
  }
  return document.location.hostname
})()
const BASE_URL = process.env.REACT_APP_API_URL 

const createKBProvider = () => {
  const getContentURL = (q: string, clientName = '', domain = DOMAIN, baseURL = BASE_URL): string => {
    return `${baseURL}/content/`
      + `?query=${encodeURIComponent(q)}`
      + `&name=${encodeURIComponent(clientName)}`
      + `&domain=${encodeURIComponent(domain)}`
  }

  const getSearchURL = (q: string, clientName = '', domain = DOMAIN, baseURL = BASE_URL): string => {
    return `${baseURL}/search/`
      + `?searchText=${encodeURIComponent(q)}`
      + `&name=${encodeURIComponent(clientName)}`
      + `&domain=${encodeURIComponent(domain)}`
  }

  const getContent = async (q: string, clientName: string): Promise<IStructureResponse> => {
    const url = getContentURL(q, clientName)
    const r = await fetch(url)
    if (r.ok !== true) {
      throw new Error()
    }
    return r.json()
  }

  const search = async (q: string, clientName: string): Promise<IStructureItem[]> => {
    const url = getSearchURL(q, clientName)
    const r = await fetch(url)
    if (r.ok !== true) {
      throw new Error()
    }
    const json = await r.json()
    return json || []
  }

  return {
    getContentURL,
    getSearchURL,
    getContent,
    search,
  }
}

export default createKBProvider()